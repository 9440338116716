import React, { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Theme
import { themeSettings } from "theme";

// Scenes
import {
  Layout,
  Dashboard,
  Products,
  Customers,
  Transactions,
  Geography,
  Overview,
  Daily,
  Monthly,
  Breakdown,
  Admin,
  Performance,
} from "scenes";
import Login from "scenes/auth/Login";
import Register from "scenes/auth/Register";
import AuthLayout from "scenes/auth";
import PrivateOutlet from "scenes/layout/PrivateOutlet";

// App
const App = () => {
  // Dark/Light mode
  const mode = useSelector((state) => state.global.mode);
  // theme setting
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/auth/*" element={ <AuthLayout/> }>
              <Route path="login" element={ <Login />} />
              <Route path="register" element={ <Register/> } />
            </Route>  
            <Route path="*" element={<PrivateOutlet />}>
              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route index path="dashboard" element={<Dashboard />} />
                <Route path="products" element={<Products />} />
                <Route path="customers" element={<Customers />} />
                <Route path="transactions" element={<Transactions />} />
                <Route path="geography" element={<Geography />} />
                <Route path="overview" element={<Overview />} />
                <Route path="daily" element={<Daily />} />
                <Route path="monthly" element={<Monthly />} />
                <Route path="breakdown" element={<Breakdown />} />
                <Route path="admin" element={<Admin />} />
                <Route path="performance" element={<Performance />} />
              </Route>
            </Route>



          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
