import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { useRegisterMutation, useVerifyMutation, } from 'state/api';
import { setCredentials } from 'state';
import { Alert } from '@mui/material';


const defaultForm = { 
  firstName:  { value: "", error: "" }, 
  lastName:   { value: "", error: "" }, 
  phone:      { value: "", error: "" }, 
  email:      { value: "", error: "" }, 
  totp:       { value: "", error: "" }, 
  remember:   { value: true, error: "" } 
}


/**
 * https://github.com/mui/material-ui/tree/v5.15.14/docs/data/material/getting-started/templates/sign-up
 * @returns 
 */
export default function Register () {

  const [errors, setErrors] = React.useState([])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register] = useRegisterMutation();
  const [verifyUser] = useVerifyMutation();

  const [form, setForm] = React.useState(defaultForm);
  const [verify, setVerify] = React.useState(false);

  const handleValueChange = (name) => 
    e => {

      const value = 
        name === 'remember' ? e.target.checked : 
        name === 'totp' ? e : e.target.value;    
        
      const error = 
        name === 'email' ? (
          e.target.validity.valid 
          && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value) ? 
            "" : "Invalid email"
          ) :
        name === 'firstName' || name === 'lastName' ? (
          /^[a-zA-Z ]+$/.test(e.target.value) ? 
            "" : "Name must contain only letters and spaces"
         ) :
        ""

      setForm(v => ({ ...v, [name]: { ...v[name], value, error } }));
      setErrors([])
    }

  const handleSubmit = async (event) => {

    event.preventDefault();

    // Forbid submission if any error
    if (Object.values(form).some(kv => kv.error)) {
      setErrors(Object.values(form).map(kv => kv.error).filter(Boolean))
      return
    } 

    // Build fields dict
    const { firstName, lastName, phone, email, totp, remember } = Object.fromEntries(
      Object.entries(form).map(([field, kv]) => [field, kv.value]));
    let user;

    try {

      if(!verify) { // Initiate registration 
        user = await register({ phone, email, name: `${firstName} ${lastName}` }).unwrap()
        if('expires' in user) { setVerify(true); }

      } else {      // Verify OTP
        user = await verifyUser({ email, totp, remember }).unwrap();
        dispatch(setCredentials(user))
        if(user?.isLoggedIn) { navigate("/"); } 
      }
    
    } catch(error) {
      console.debug("There was an error", error)
      setErrors([error.data || error.error])
    }
  };

  
  return (
    <Box m="1.5rem 2.5rem">
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        {errors?.map((message, key) => (
          <Alert key={key} severity="error">{message}</Alert>)) 
        }

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={form.firstName.value}
                  error={!!form.firstName.error}
                  helperText={form.firstName.error || "Your given name."}
                  onChange={handleValueChange('firstName')}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={form.lastName.value}
                  error={!!form.lastName.error}
                  helperText={form.lastName.error || "Your family name."}
                  onChange={handleValueChange('lastName')}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={form.phone.value}
                  error={!!form.phone.error}
                  helperText={form.phone.error || "Your mobile phone number."}
                  onChange={handleValueChange('phone')}
                  required
                  fullWidth
                  id="phone"
                  label="Mobile Phone"
                  name="phone"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={form.email.value}
                  error={!!form.email.error}
                  helperText={form.email.error || "Enter valid email."}
                  onChange={handleValueChange('email')}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  required
                  // inputProps={{ pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                  // inputProps={{
                  //   pattern: "[A-Za-z ]+",
                  // }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ ...(!verify && { display: "none"}), ml: 0 }}
                  labelPlacement='bottom'
                  label={
                    <Typography variant='caption' color="secondary" 
                      sx={{ ml: 2, mt: 1, alignSelf: 'flex-start'}}>
                      6-digits temporary password sent to "{form.email.value}"
                    </Typography>
                  }
                  control={
                  <MuiOtpInput 
                    length={6}
                    label="One-time Password"
                    name="totp"
                    value={form.totp.value} 
                    onChange={handleValueChange('totp')} 
                  />} 
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  {...(!verify && { display: "none"})}
                  label="Remember me"
                  control={
                    <Checkbox 
                      checked={!!form.remember.value} 
                      onChange={handleValueChange('remember')} 
                      color="primary" 
                    />
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,  bgcolor: 'secondary.main', color: 'background.default' }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/auth/login" variant="body2" sx={{ color: 'secondary.main' }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}