import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';


export default function (props) {

  return (
    <Button
      sx={{ borderRadius: 10 }}
      startIcon={<RefreshIcon />}
      {...props}
    >
      <Typography variant='caption' >
        REFRESH
      </Typography>
    </Button>
  )
}