import { createSlice } from "@reduxjs/toolkit";

// initial redux state
const initialState = {
  mode: "light",
  user: null,
};

// reducers to get redux state data
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setCredentials: (state, { payload }) => {
      state.user = payload
    },
    clearState: () => {
      return initialState;
    },
  },
});


export const { setMode, setCredentials, clearState } = globalSlice.actions;

export default globalSlice.reducer;

export const selectCurrentUser = (state) => {
  return state.global.user
}

