import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";

import { useGetTransactionsQuery } from "state/api";
import { Header, DataGridCustomToolbar } from "components";

// Transactions
const Transactions = () => {
  // theme
  const theme = useTheme();

  // values for backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  // search
  const [searchInput, setSearchInput] = useState("");
  // get data
  const { data, isLoading, refetch } = useGetTransactionsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
  });

  // data columns
  const columns = [
    {
      field: "useSandbox",
      headerName: "Sandbox?",
      flex: .2,
      renderCell: (params) => params.value ? 'Sandbox' : 'Prod',
    },
    {
      field: "_id",
      headerName: "ID",
      flex: .3,
    },
    {
      field: "products",
      headerName: "Products",
      flex: .3,
      renderCell: (params) => params.value?.map(p => p.name).join(', ')
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 0.3,
      renderCell: (params) => params.value?.name,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: .3,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Status",
      flex: .2,
      renderCell: (params) => 
        +params.value > 0 ? "SUCCESS" : 
        +params.value < 0 ? "FAILED"  :
        "INITIATED" 
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: .5,
      valueGetter: ({value, row}) => {
        return `${row.currency} ${Number(value).toFixed(2)}`;
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: .5,
      valueGetter: ({value, row}) => {
        return `${row.currency} ${Number(value).toFixed(2)}`;
      },
    },
  ];

  console.log('>>>>>>> data=', data)

  return (
    <Box m="1.5rem 2.5rem">
      {/* Header */}
      <Header title="Transactions" subtitle="Entire list of transactions" />

      {/* Content */}
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtom-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {/* Grid Table */}
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch, refresh: refetch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Transactions;
