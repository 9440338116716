/**
 * https://m2.material.io/inline-tools/color/
 * https://mui.com/material-ui/customization/color/
 * https://mui.com/material-ui/customization/palette/
 */


// color design tokens export
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#e0f3f5",
    100: "#b1e3e5",
    200: "#7cd1d4",
    300: "#3ebfc1",
    400: "#00b0b1",
    500: "#00a1a0",
    600: "#009492",
    700: "#008480",
    800: "#00736f",
    900: "#005751",
    1000: "#000000", // manually adjusted
  },
  primary: {
    50: "#f5f5f5",
    100: "#e9e9e9",
    200: "#d9d9d9",
    300: "#c4c4c4",
    400: "#9d9d9d",
    500: "#7b7b7b",
    600: "#555555", 
    700: "#434343",
    800: "#262626",
    900: "#000000",
  },
  secondary: {
    50: "#e0f3f5",
    100: "#b1e3e5",
    200: "#7cd1d4",
    300: "#3ebfc1",
    400: "#00b0b1",
    500: "#00a1a0",
    600: "#009492",
    700: "#008480",
    800: "#00736f",
    900: "#005751",
  },

  // primary: blue,
  // secondary: cyan

};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.primary[600],
              alt: tokensDark.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.grey[50],
              light: tokensDark.grey[100],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.grey[50],
            },
          }),
    },
    typography: {
      fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
