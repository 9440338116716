import React from 'react';
import { Outlet } from 'react-router-dom';
import Copyright from 'components/Copyright';


export default function AuthLayout() {
  return (
    <>
      <Outlet/>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </>
  )
}

