import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'



export default function PrivateOutlet() {
  
  const auth = useAuth()
  const location = useLocation()

  return auth.user ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} />
  )
}
