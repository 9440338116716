import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { useLoginMutation, useVerifyMutation } from 'state/api';
import { setCredentials } from 'state';
import { Alert } from '@mui/material';



const defaultForm = { 
  totp:       { value: "", error: "" }, 
  email:      { value: "", error: "" }, 
  remember:   { value: true, error: "" } 
}


/**
 * https://github.com/mui/material-ui/tree/v5.15.14/docs/data/material/getting-started/templates/sign-in
 * @returns 
 */
export default function Login() {


  const [loginUser] = useLoginMutation();
  const [verifyUser] = useVerifyMutation();

  const [errors, setErrors] = React.useState([])
  const [form, setForm] = React.useState(defaultForm);
  const [verify, setVerify] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleValueChange = (name) => 
    e => {

      /* Field validation 
      e.target.validity.valid => native HTML validation, ie. required, etc.*/
      let error, value; 
      switch(name) {

        case 'email':
          value = e.target.value;
          error = e.target.validity.valid 
            && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value) ? 
            "" : "Invalid email";
          break;

        case 'remember': 
          value = e.target.checked;
          break;

        case 'totp':
          value = e;
          break;

        default: 
          throw new Error(`Unknown field "${name}"`)
      }

      setForm(v => ({ ...v, [name]: { ...v[name], value, error } }));
      setErrors([])
  }
  
  const handleSubmit = async (event) => {
    
    event.preventDefault();

    // Forbid submission if any error (.error non-truthy)
    if (Object.values(form).some(kv => kv.error)) {
      setErrors(Object.values(form).map(kv => kv.error).filter(Boolean))
      return
    } 

    // Build fields dict
    const { email, totp, remember } = Object.fromEntries(
      Object.entries(form).map(([field, kv]) => [field, kv.value]));
    let user;

    try {

      if(!verify) { // Initiate login 
        user = await loginUser({email}).unwrap()
        if('expires' in user) { setVerify(true); }

      } else {      // Verify OTP
        user = await verifyUser({ email, totp, remember }).unwrap();
        dispatch(setCredentials(user))
        if(user?.isLoggedIn) { navigate("/"); } 
      }
    
    } catch(error) {
      console.debug("There was an error", error)
      setErrors([error.data || error.error])
    }

  };

  return (
    <Box m="1.5rem 2.5rem">

      <Container component="main" maxWidth="xs">
        <CssBaseline />

        {errors?.map((message, key) => (
          <Alert key={key} severity="error">{message}</Alert>)) 
        }
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              value={form.email.value}
              error={!!form.email.error}
              onChange={handleValueChange('email')}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              helperText={form.email.error || "Partner email."}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <FormControlLabel
              sx={{ ...(!verify && { display: "none"}), ml: 0 }}
              labelPlacement='bottom'
              label={
                <Typography variant='caption' color="secondary" 
                  sx={{ ml: 2, mt: 1, alignSelf: 'flex-start'}}>
                  6-digits temporary password sent to "{form.email.value}"
                </Typography>
              }
              control={
              <MuiOtpInput 
                length={6}
                label="One-time Password"

                name="totp"
                value={form.totp.value} 
                onChange={handleValueChange('totp')} 
              />} 
            />

            <FormControlLabel
              {...(!verify && { display: "none"})}
              sx={{ mt: 3 }}
              label="Remember me"
              control={
                <Checkbox 
                  checked={!!form.remember.value} 
                  onChange={handleValueChange('remember')} 
                  color="primary" 
                />
              }
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, mb: 2, 
                color: 'background.default',        
                bgcolor: 'secondary.main'
              }}
            >
              {!verify ? 'Sign In' : 'Verify' }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" sx={{ color: 'secondary.main' }}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/auth/register" variant="body2" sx={{ color: 'secondary.main' }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}